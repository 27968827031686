.footerContainer {
    width: 100%;
    height: 350px;
    background-color: #2C3437;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 5px;
}

.footerContainer img {
    width: 55px;
    height: 55px;
    margin-bottom: 10px;
}

.footerContainer p {
    color: #727272;
    font-size: .9rem;
    font-family: 'RobotoCondensed-Regular';
    user-select: none;
}

.navbarFooterContainer {
    background-color: transparent;
    color: #FFFFFF;
    display: flex;
    justify-content: center;
    align-items: center;
    user-select: none;
    font-family: 'RobotoCondensed-Regular';
    padding: 10px 0;
    bottom: 0;
}

.flexFooterNavigation {
    display: flex;
    list-style: none;
    align-items: center;
    padding: 0;
}

.flexFooterNavigation li {
    margin: 0 30px;
    font-size: 1.4rem;
}

.flexFooterNavigation li a {
    text-decoration: none;
    color: #FFFFFF;
    transition: all .2s ease-in-out;
    font-family: 'RobotoCondensed-Regular';
}

.footerSocials {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    gap: 50px;
    margin-top: 10px;
}

.footerSocials img {
    width: 35px;
    height: 35px;
    transition: all .2s ease-in-out;
}

@media (max-width: 600px) {
    .flexFooterNavigation li {
        margin: 0 10px;
    }

    .flexFooterNavigation li a {
        font-size: 1.2rem;
    }
}

@media (max-width: 400px) {
    .flexFooterNavigation li {
        margin: 0 5px;
    }

    .flexFooterNavigation li a {
        font-size: 1rem;
    }
}

@media (max-width: 330px) {
    .flexFooterNavigation li {
        margin: 0 2px;
    }

    .footerContainer p {
        font-size: .8rem;
    }

    .flexFooterNavigation li a {
        font-size: .8rem;
    }
}