.navbarBack {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.headerBackground {
    margin-top: 50px;
    z-index: 4;
    position: relative;
    animation: fadeIn 4s;
}

.backgroundSphere {
    background: rgb(52,147,181);
    background: radial-gradient(circle, rgba(52,147,181,0.5) 0%, rgba(38,38,38,0.5) 100%); 
    pointer-events: none;
    width: 50%;
    height: 70%;
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    margin-top: 200px;
    top: 0;
    left: 0;
    right: 0;
    filter: blur(30px);
    border-radius: 50%;
    opacity: .4;
}

@keyframes fadeIn {
    0% {opacity: 0;}
    100% {opacity: 1;}
}

.navbarContainer {
    background-color: transparent;
    color: #FFFFFF;
    display: flex;
    justify-content: center;
    align-items: center;
    user-select: none;
    font-family: 'RobotoCondensed-Regular';
    padding: 10px 0;
    bottom: 0;
}

.indexMenu {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 30px;
    width: 100%;
    height: 75px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 999;
    background-color: rgb(33, 38, 42, 1);
    animation: fadeIn 1s;
}

.flexIndex {
    display: flex;
    align-items: center;
}

.flexIndex h2 {
    font-size: 2rem;
    font-weight: bold;
    color: #FFFFFF;
    padding-left: 10px;
    user-select: none;
    font-family: 'RobotoCondensed-Regular';
}

.flexIndex img {
    width: 60px;
    height: 60px;
    /* animation: rotation linear 8s infinite; */
}

/* @keyframes rotation {
    from { 
        -moz-transform: rotateY(0deg); 
        -ms-transform: rotateY(0deg); 
        transform: rotateY(0deg); 
    }
    to 
    { 
        -moz-transform: rotateY(-360deg); 
        -ms-transform: rotateY(-360deg); 
        transform: rotateY(-360deg); 
    }
} */

.flexNavigation {
    display: flex;
    list-style: none;
    align-items: center;
    padding: 0;
}

.flexNavigation li {
    margin: 0 30px;
    font-size: 1.4rem;
}

.flexNavigation li a {
    text-decoration: none;
    color: #FFFFFF;
    transition: all .2s ease-in-out;
    font-family: 'RobotoCondensed-Regular';
}

.flexNavigation li a:hover {
    color: #bebebe;
    transition: all .2s ease-in-out;
}

nav.stroke ul li a {
    position: relative;
}

.hamMenu {
    z-index: 90;
    position: absolute;
    top: 7px;
    right: 12px;
    display: none;
}

.hamMenu input {
    display: none;
}

.hamMenu img {
    width: 40px;
    cursor: pointer;
    position: fixed;
    z-index: 999;
    top: 15px;
    right: 15px;
    background-color: #424c4e;
    padding: 3px;
    border-radius: .4rem;
}

.flexHam {
    position: fixed;
    display: none;
    top: 65px;
    right: 20px;
    background-color: #424c4e;
    color: rgb(231, 231, 231);
    padding: 10px 20px;
    z-index: 999;
    border-radius: .8rem;
}

.flexHam a:hover {
    color: white;
}

.triangle {
    position: absolute;
    top: -12px;
    right: 4px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 7.5px 15px 7.5px;
    border-color: transparent transparent #424c4e transparent;
}

#hamMenu:checked ~ .flexHam {
    display: block;
}

@media (max-width: 1100px) {
    .flexNavigation li a {
        font-size: 1.2rem;
    }

    .flexIndex h2 {
        font-size: 1.5rem;
    }
}

@media (max-width: 950px) {
    .flexNavigation li {
        margin: 0 20px;
    }

    .flexNavigation li a {
        font-size: 1.1rem;
    }

    .flexIndex h2 {
        font-size: 1.3rem;
    }

    .backgroundSphere {
        margin-top: 50px;
    }
}

@media (max-width: 780px) {
    .indexMenu {
        display: none;
    }

    .hamMenu {
        display: block;
    }

    .backgroundSphere {
        margin-top: 0px;
    }
}