.carouselContainer {
    width: 100%;
    margin: 0px auto 70px auto;
    animation: fadeIn 3s;
}

.carouselContainer img {
    width: 100%;
    height: 700px;
}

@media ((min-width: 1100px) and (max-width: 1350px)) {
    .carouselContainer img {
        height: 500px;
    }
}

@media ((min-width: 800px) and (max-width: 1100px)) {
    .carouselContainer img {
        height: 450px;
    }
}

@media ((min-width: 650px) and (max-width: 800px)) {
    .carouselContainer img {
        height: 300px;
    }
}

@media (max-width: 650px) {
    .carouselContainer {
        display: none;
    }
}