.descriptionParagraph {
    height: 0;
    overflow: hidden;
    transition: .5s;
    font-size: 1rem;
}

.openDescription.active ~ .descriptionParagraph {
    height: 160px;
    margin-top: 10px;
}

.faqContainer {
    background-color: #2c3437;
    font-family: 'RobotoCondensed-Regular';
}

.faqItem {
    background-color: #21282a;
}

@media (max-width: 1000px) {
    .faqTitle {
        font-size: 1.7rem;
    }
}