@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #21282a;
  color: white;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html {
  scroll-behavior: smooth;
}

@font-face {
  font-family: "RobotoCondensed-Light";
  src: local('RobotoCondensed-Light'), url('./fonts/roboto_condensed/RobotoCondensed-Light.ttf') format('truetype');
}

@font-face {
  font-family: "RobotoCondensed-Regular";
  src: local('RobotoCondensed-Regular'), url('./fonts/roboto_condensed/RobotoCondensed-Regular.ttf') format('truetype');
}

@font-face {
  font-family: "RobotoCondensed-Bold";
  src: local('RobotoCondensed-Bold'), url('./fonts/roboto_condensed/RobotoCondensed-Bold.ttf') format('truetype');
}