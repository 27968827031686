.bodyContent {
    margin-bottom: 120px;
}

.sectionContent {
    display: flex;
    gap: 50px;
    padding: 0 70px;
    margin-top: 15px;
}

.sectionContainer {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 33%;
    margin-right: auto;
    margin-left: auto;
    padding: 40px 20px;
    color: white;
    height: auto;
    transition: .4s;
    background-color: #2c3437;
    backdrop-filter: blur( 7.5px );
    -webkit-backdrop-filter: blur( 7.5px );
    border-radius: 10px;
    border: 1px solid rgba( 255, 255, 255, 0.18 );
    font-family: 'RobotoCondensed-Regular';
}

.dieselTitle {
    font-size: 2.5rem;
    text-align: center;
    font-weight: bold;
    font-family: 'RobotoCondensed-Bold';
}

.dieselDescription {
    text-align: center;
    font-size: 1.4rem;
    font-family: 'RobotoCondensed-Regular';
}

.sectionContainer:hover {
    transition: .4s;
    background: #323d41;
    transform: scale(1.05);
}

.sectionContainer h2 {
    text-align: center;
    font-size: 1.8rem;
    font-weight: 600;
}

.sectionContainer a {
    margin-top: 20px;
}

.sectionText {
    font-size: 1.5rem;
    text-align: center;
    width: 80%;
    margin-top: 10px;
}

.sectionAnchor {
    font-size: 1.4rem;
    border: 2px solid #828385;
    padding: 5px 10px;
    border-radius: .5rem;
    transition: 1s;
}

.sectionAnchor:hover {
    color: black;
    box-shadow: inset 0 0 0 2.5rem #ffffff;
}

.logoTitle {
    width: 100px;
    margin: 0 auto;
}

@media ((min-width: 900px) and (max-width: 1200px)) {
    .sectionContent {
        flex-direction: column;
        gap: 30px;
    }

    .sectionContainer {
        width: 85%;
    }
}

@media ((min-width: 800px) and (max-width: 900px)) {
    .dieselTitle {
        font-size: 2.2rem;
    }

    .sectionContent {
        flex-direction: column;
        gap: 30px;
    }

    .sectionContainer {
        width: 85%;
    }
}

@media ((min-width: 650px) and (max-width: 800px)) {
    .dieselTitle {
        font-size: 1.9rem;
    }

    .dieselDescription {
        font-size: 1.2rem;
    }

    .sectionContent {
        flex-direction: column;
        gap: 30px;
    }

    .sectionContainer {
        width: 85%;
    }
}

@media ((min-width: 400px) and (max-width: 650px)) {
    .dieselTitle {
        font-size: 1.7rem;
        margin-top: 10px;
    }

    .logoTitle {
        margin-top: 70px;
    }

    .dieselDescription {
        font-size: 1.2rem;
    }

    .sectionContent {
        flex-direction: column;
        gap: 30px;
        padding: 0 20px;
    }

    .sectionContainer {
        width: 85%;
    }

    .sectionContainer h2 {
        font-size: 1.5rem;
    }

    .sectionText {
        font-size: 1.3rem;
    }
}

@media (max-width: 400px) {
    .dieselTitle {
        font-size: 1.5rem;
        margin-top: 10px;
    }

    .logoTitle {
        margin-top: 70px;
        width: 70px;
    }

    .dieselDescription {
        font-size: 1.2rem;
    }

    .sectionContent {
        flex-direction: column;
        gap: 15px;
        padding: 0 10px;
    }

    .sectionContainer {
        width: 90%;
    }

    .sectionContainer h2 {
        font-size: 1.3rem;
    }

    .sectionText {
        font-size: 1rem;
    }
}