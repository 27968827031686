.contactContainer {
    margin: 90px 0 130px 0;
    font-family: 'RobotoCondensed-Regular';
}

.contactContainer h2 {
    text-align: center;
    font-size: 1.8rem;
    font-weight: 600;
}

.contactSection {
    display: flex;
    margin: 10px auto;
    justify-content: center;
    align-items: center;
    min-height: 500px;
    height: auto;
}

.contactData {
    width: 30%;
    height: 700px;
    border: 8px solid #2c3437;
    color: white;
    padding: 50px 0 0 50px;
    text-align: center;
    border-top-left-radius: .7rem;
    border-bottom-left-radius: .7rem;
    background-image: url('../../../public/media/cBackground.png');
    background-repeat: no-repeat;
    background-position: 100% 100%;
}

.contactData h3 {
    font-size: 1.9rem;
    font-weight: 600;
    text-align: left;
}

.contactFlex {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    gap: 45px;
    padding-top: 35px;
}

.cFlexElement {
    display: flex;
    justify-content: center;
    align-items: center;
}
.cFlexElement span {
    color: rgb(133, 133, 133);
}

.contactFlex img {
    width: 40px;
    transition: .3s;
    cursor: pointer;
    margin-bottom: 5px;
    margin-right: 20px;
}

.contactFlex img:hover {
    transform: scale(1.15);
    transition: .7s;
}

.contactIframe {
    width: 50%;
}

.contactIframe iframe {
    border-bottom-right-radius: .3rem;
    border-top-right-radius: .3rem;
}

@media ((min-width: 1200px) and (max-width: 1300px)) {
    .cFlexElement p {
        word-break: break-all;
        white-space: normal;
        padding: 0 10px;
    }
}

@media ((min-width: 1000px) and (max-width: 1200px)) {
    .contactSection {
        flex-direction: column;
    }

    .contactData {
        width: 70%;
        border-bottom-left-radius: 0;
        border-top-right-radius: .7rem;
        height: 200px;
    }

    .contactData h3 {
        text-align: center;
    }

    .contactFlex {
        flex-direction: row;
        gap: 100px;
    }

    .cFlexElement p {
        word-break: break-all;
        white-space: normal;
        padding: 0 10px;
        display: none;
    }

    .contactIframe {
        width: 70%;
    }
}

@media ((min-width: 450px) and (max-width: 1000px)) {
    .contactContainer h2 {
        font-size: 1.5rem;
    }

    .contactData h3 {
        text-align: center;
    }

    .contactSection {
        flex-direction: column;
    }

    .contactData {
        width: 80%;
        border-bottom-left-radius: 0;
        border-top-right-radius: .7rem;
        height: 200px;
    }

    .contactFlex {
        flex-direction: row;
        gap: 50px;
    }

    .cFlexElement p {
        word-break: break-all;
        white-space: normal;
        padding: 0 10px;
        display: none;
    }

    .contactIframe {
        width: 80%;
    }
}

@media (max-width: 450px) {
    .contactContainer h2 {
        font-size: 1.3rem;
    }

    .contactSection {
        flex-direction: column;
    }

    .contactData {
        width: 90%;
        border-bottom-left-radius: 0;
        border-top-right-radius: .7rem;
        height: 200px;
    }

    .contactFlex {
        flex-direction: row;
        gap: 20px;
    }

    .contactFlex img {
        width: 50px;
    }

    .cFlexElement p {
        word-break: break-all;
        white-space: normal;
        padding: 0 10px;
        display: none;
    }

    .contactIframe {
        width: 90%;
    }
}