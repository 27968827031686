.fixedContainer {
    display: flex;
    align-items: center;
    position: fixed;
    right: 20px;
    bottom: 20px;
    gap: 20px;
    z-index: 99;
}

.contactFixed {
    border: 1px solid #fff;
    border-radius: .2rem;
    padding: 5px 10px;
    transition: all .4s;
}

.contactFixed:hover {
    background-color: rgba(4, 75, 133, 0.582);
}

.whatsappButton img {
    width: 65px;
    height: 65px;
}